<template>
  <nuxt-img
    v-if="src && !loading"
    :src="src"
    placeholder
    loading="lazy"
    class="h-full w-full bg-fr-gray-100 object-cover dark:bg-fr-gray-900"
    :class="{
      'opacity-0': loading,
      'opacity-100': !loading
    }"
  />
  <div
    v-else-if="loading"
    class="h-full w-full bg-fr-gray-100 object-cover dark:bg-fr-gray-900"
  >
    <q-skeleton class="h-full w-full object-cover" />
  </div>
  <div
    v-else-if="!src"
    class="h-full w-full bg-fr-gray-100 object-cover dark:bg-fr-gray-900"
  >
    <p class="text-center text-fr-gray-500 dark:text-fr-gray-400">No Image</p>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  src: string
}>()

const loading = ref(false)
</script>
